<template>
    <div>
        <label v-if="label">{{required ? label + '*' : label}}</label>
        <p v-if="description" v-html="description" class="description"></p>
        <Banner type="message" v-else-if="bannerMessage" :bannerMessage="bannerMessage" />
        <div style="display: inline-block; width: auto;">
            <v-btn-toggle v-if="options && options.length"
                :class="errors && errors.length ? 'hasError' : ''"
                v-model="newValue" 
                :value="newValue"
                :mandatory="mandatory"
                left 
                style="margin-top: 4px;"
                @change="setValue"
                background-color="white">
                <v-btn                    
                    v-for="option in options" 
                    :key="option.value"
                    :class="{'small': small}"
                    :value="option.value"
                    :outlined="true"
                    :min-height="small ? 20 : 30"
                    :min-width="small ? 60 : 100"
                    color="#002B49" 
                    :disabled="disabled || option.disabled"
                    
                    >
                    <div style="display: block;">
                        <span v-if="option.text" class="label-primary" v-html="option.text"></span>
                        <span v-if="option.subtext" class="label-secondary" v-html="option.subtext"></span>
                    </div>
                </v-btn>
            </v-btn-toggle>
            <ErrorMessages :errors="errors"></ErrorMessages>
        </div>
    </div>
</template>

<script>
import Banner from '@/components/ui/Banner.vue';

    export default {
        name: 'RadioButtonGroup',
        components: {
            Banner
        },
        props: {
            options: Array,
            rules: String,
            value: [Number, String, Boolean],
            label: String,
            required: Boolean,
            errors: Array,
            small: Boolean,
            disabled: Boolean,
            bannerMessage: String,
            description: String,
            mandatory: {
                type: Boolean,
                default: false
            }
        },
        emits: [
            'change'
        ],
        data() {
            return {
                newValue: null,
            }
        },
        methods: {
            emitValue() {
                this.$emit('change', this.newValue)  
            },
            setValue() {
                this.emitValue();
            }
        },
        watch: {
            value: {
                immediate: true,
                handler(newVal) {
                    this.newValue = newVal;
                }
            },
        },
        mounted() {
        }
    }
</script>

<style scoped lang="scss">
    @import '@/style.scss';

    ::v-deep .v-btn-toggle:not(.v-btn-toggle--dense) .v-btn.v-btn.v-size--default {
        height: 40px;
        min-height: 40px;
    }

    ::v-deep .v-btn {
        letter-spacing: inherit!important;
    }

    ::v-deep .v-btn.small {
        height: 32px!important;

        .v-btn__content {
            font-size: 0.8rem;
        }
    }

    .v-btn--active {
        background-color: $amwins-blue;

        &.v-btn--disabled {
            background-color: $gray5;
        }
    }

    .v-btn--active::v-deep .v-btn__content {
        color: white!important;
    }

    .theme--light.v-btn-toggle:not(.v-btn-toggle--group) .v-btn.v-btn {
        border-color: $amwins-blue!important;
    }

    .theme--light.v-btn-toggle:not(.v-btn-toggle--group) .v-btn.v-btn--disabled {
        border-color: $gray3!important;
    }

    .hasError.theme--light.v-btn-toggle:not(.v-btn-toggle--group) .v-btn.v-btn {
        border-color: $tertiary-red!important;
    }

    .v-btn-toggle > .v-btn.v-btn {
        border-width: 2px;
        opacity: 1;
    }

    .v-btn-toggle {
        display: block;

        & > button.v-btn.v-btn:not(:first-child) {
            border-left-width: 1px;
        }

        & > button.v-btn.v-btn:not(:last-child) {
            border-right-width: 1px;
        }
    }

    label {
        display: block;
        font-weight: bold;
        margin-bottom: 5px;
    }

    .description {
        margin-bottom: 0;
        font-size: 12px;
        margin-top: 5px;
    }

    .label-primary {
        display: block;
    }

    .label-secondary {
        display: block;
        font-size: 12px;
        line-height: 10px;
    }
    
</style>