<template>
    <div>
        <v-checkbox
            v-model="newValue"
            color="#002B49"
            :disabled="disabled"
            hide-details
            :class="errors && errors.length ? 'hasError' : ''"
            :error="errors && errors.length > 0"
        >
            <template v-slot:label>
                <p v-html="label" class="mb-0"></p>
            </template>
        </v-checkbox>
        <ErrorMessages :errors="errors"></ErrorMessages>
    </div> 
</template>

<script>

    export default {
        name: 'Checkbox',
        props: {
            value: Boolean,
            label: String,
            rules: String,
            errors: Array,
            disabled: {
                type: Boolean,
                default: false
            },
        },
        emits: [
            'change'
        ],
        data() {
            return {
                newValue: null
            }
        },
        watch: {
            value: {
                immediate: true,
                handler(newVal) {
                    if (newVal === undefined || newVal === null) {
                        this.newValue = false;
                        this.emitValue();
                    } else {
                        this.newValue = newVal;
                    }
                }   
            },
            newValue() {
                this.emitValue();
            }
        },
        methods: {
            emitValue() {
                this.$emit('change', this.newValue)       
            },
        },
        mounted() {
        }
    }
</script>

<style scoped lang="scss">
    @import '@/style.scss';

   ::v-deep .theme--light.v-icon.v-icon {
        color: $amwins-blue;
    }

    ::v-deep .v-label {
        color: $gray1;
    }

    ::v-deep .v-input--selection-controls {
        margin-top: 0;
    }
</style>
