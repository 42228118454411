<!-- NOTE: this Banner component exists in the digital-ui project, and should be switched to when possible -->

<template>
  <div class="banner-wrapper" :class="`banner-${type}`">
    <div v-show="type === 'success'">
      <i :class="['fa-light fa-circle-check', 'banner-success', 'banner-icon']"></i>
    </div>
    <div v-show="type === 'info'">
      <i :class="['fa-light fa-lightbulb', 'banner-info', 'banner-icon']"></i>
    </div>
    <div v-show="type === 'error'">
      <i :class="['fa-light fa-triangle-exclamation', 'banner-error', 'banner-icon']"></i>
    </div>
    <div v-show="type === 'message'">
      <i :class="['fa-light fa-bell', 'banner-message', 'banner-icon']"></i>
    </div>
    <slot>
      <div class="banner-content" @click="performBannerAction">
        <span v-if="bannerTitle" v-html="bannerTitle" class="banner-title"></span>
        <span v-if="bannerTitle">&nbsp;-&nbsp;</span>
        <span v-html="bannerMessage"></span>
      </div>
    </slot>
  </div>
</template>

<script>
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'Banner',
  props: {
    type: {
      type: String,
      default: 'success',
      validator (val) {
        return [
          'success',
          'message',
          'error',
          'info'
        ].indexOf(val) !== -1;
      }
    },
    bannerTitle: {
      type: String
    },
    bannerMessage: {
      type: String
    },
    bannerAction: {
      type: Function
    }
  },
  methods: {
    performBannerAction (event) {
      if (this.bannerAction) {
        this.bannerAction(event);
      }
    }
  }
});
</script>

<style lang="scss" scoped>
.banner-wrapper {
  display: flex;
  padding-top: 1rem;
  padding-bottom: 1rem;
  margin-top: 1rem;
  margin-bottom: 1rem;
  border-radius: 0.25rem;
  line-height: 1.25;
  padding-right: 1rem;
  align-items: center;

  .banner-icon {
    margin-left: 1rem;
    margin-right: 1rem;
  }

  .banner-content {
    color: #333333;
    line-height: 14px;

    .banner-title {
      font-weight: bold;
    }
  }
}
.banner-success {
  background-color: #ECFFEE;
  color: #60C76A;
  &.banner-wrapper {
    border: 1px solid #60C76A;
  }
}
.banner-message {
  background-color: #EEEEEE;
  color: #333333;
  &.banner-wrapper {
    border: 1px solid #333333;
  }
}
.banner-error {
  background-color: #FFEEEE;
  color: #9F2242;
  &.banner-wrapper {
    border: 1px solid #9F2242;
  }
}
.banner-info {
  background-color: rgba(#A2D6E7, 0.5);
  color: #333333;
  &.banner-wrapper {
    border: 1px solid #215479;
  }
}
</style>
