<template>
  <div>
    <v-container fluid class="ml-0 pl-0 mt-4">
      <v-row>
        <v-col>
          <div class="mortgagee-wrapper">
            <div><b>Please note that any or all of the following documents will be sent to you via email, coming from an @amwins.com email address:</b></div>
            
            <div v-for="(document, index) in invoiceDocuments" class="document-wrapper" :key="`invoice-document-${index}`">
              <div class="check-icon">
                <i class="fas fa-check-circle fa-lg"></i>
              </div>
              {{ document }}
            </div>
          </div>
        </v-col>
      </v-row>
    </v-container>

    <v-container fluid class="ml-0 pl-0 mt-4">
      <v-row>
        <v-col>
          <RadioButtonGroup
            :options="notificationOptions"
            :value="insuredEmailOptions.InsuredReceivesPolicyEmails"
            :bannerMessage="this.endorsement ? 'Any changes made to the insured email preference will be applied to all future communications to the insured for this policy and renewal.' : ''"
            label="Does the insured want to receive policy communications via email, such as invoices, endorsement documents, or any notices of cancellation?"
            @change="val => updateInsuredEmailOptions({ name: 'InsuredReceivesPolicyEmails', value: val })"
            :errors="notificationError"
            :required="true"
            class="notification-wrapper"/>
        </v-col>
      </v-row>
    </v-container>

    <v-container fluid class="ml-6">
      <v-row>
        <v-col>
          <div v-show="insuredEmailOptions.InsuredReceivesPolicyEmails===true" class="notifications-on">
            <TextInput
              label="Email Address"
              class="col-md-6 ml-n2"
              :clearable="true"
              :errors="emailError"
              v-model="insuredEmailOptions.InsuredEmailAddress"
              @change="val => updateInsuredEmailOptions({ name: 'InsuredEmailAddress', value: val })"/>
            <Checkbox
              :label="attestationText"
              :value="attestationValue"
              :errors="attestationError"
              @change="val => updateAttestation(val)" />
          </div>
          <div v-show="insuredEmailOptions.InsuredReceivesPolicyEmails===false" class="notifications-off">
            <v-icon class="thumb-icon">fa-solid fa-thumbs-up</v-icon>
            <b>Not a problem!</b> We will make sure to physically mail a copy of the invoice to the insured at renewal.
          </div>
        </v-col>
      </v-row>
    </v-container>

    <v-container v-if="mortgageeCompany" fluid class="ml-0 pl-0 mt-4">
      <v-row>
        <v-col>
          <div class="mortgagee-wrapper">
            <div><b>For your convenience, we will physically mail a copy of the following documents to the mortgage company listed below:</b></div>
            <div v-for="(document, index) in mortgageeDocuments" class="document-wrapper" :key="`mortgagee-document-${index}`">
              <div class="check-icon">
                <i class="fas fa-check-circle fa-lg"></i>
              </div>
              {{ document }}
            </div>
            <div class="mortgage-data-container">
              <div class="mortgage-data-wrapper">
              <div class="small-title bold">Primary Mortgage Company</div>
              <div class="bold">{{ mortgageeCompany.MortgageeName }}</div>
              <div>{{ mortgageeCompany.MortgageeAddress1 }}</div>
              <div>{{ mortgageeCompany.MortgageeCity }}, {{ mortgageeCompany.MortgageeState }} {{ mortgageeCompany.MortgageeZip }}</div>
            </div>
            <div class="mortgage-data-wrapper" v-if="secondaryMortgageCompany">
              <div class="small-title bold">Secondary Mortgage Company</div>
              <div class="bold">{{ secondaryMortgageCompany.MortgageeName }}</div>
              <div>{{ secondaryMortgageCompany.MortgageeAddress1 }}</div>
              <div>{{ secondaryMortgageCompany.MortgageeCity }}, {{ secondaryMortgageCompany.MortgageeState }} {{ secondaryMortgageCompany.MortgageeZip }}</div>
            </div>
            </div>
          </div>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import { defineComponent } from 'vue';

import RadioButtonGroup from '@/components/form/RadioButtonGroup.vue';
import Checkbox from '@/components/form/Checkbox.vue';
import TextInput from '@/components/form/TextInput.vue';
import { email } from 'vee-validate/dist/rules';

export default defineComponent({
  name: 'InsuredEmailOptions',
  components: {
    RadioButtonGroup,
    Checkbox,
    TextInput
  },
  props: {
    value: [String, Object],
    errors: Array,
    disabled: Boolean,
    endorsement: Boolean,
    module: String
  },
  emits: [
    'change',
    'validate'
  ],
  data() {
    return {
      newValue: null,
      loading: false,
      insuredEmailOptions: {
        InsuredReceivesPolicyEmails: null,
        InsuredEmailAddress: null,
        InsuredEmailAttestationText: null
      },
      notificationOptions: [
        {
          text: 'Yes',
          value: true
        },
        {
          text: 'No',
          value: false
        }
      ],
      attestationValue: null,
      attestationText: 'The insured entity has granted consent to receiving emails about their policy, such as invoices, endorsement documents, or any notices of cancellation.',
      emailError: null,
      notificationError: null,
      attestationError: null,
      validationEnabled: false,
      isValid: false,
      isDirty: true
    }
  },
  watch: {
    errors(errors) {
      if (errors && errors.length) {
        this.validateInternal();
      }
    },
    insuredEmailOptions: {
      deep: true,
      handler(newVal) {
        if (newVal?.InsuredReceivesPolicyEmails === false) {
          this.insuredEmailOptions.InsuredEmailAddress = null;
          this.insuredEmailOptions.InsuredEmailAttestationText = null;
          this.attestationValue = null;
        }
      }
    }
  },
  computed: {
    invoiceDocuments () {
      if (this.endorsement) {
        return [
          'Endorsement invoices',
          'Endorsement declaration page'
        ];
      }
      return [
        'Policy document',
        'Endorsement invoices',
        'Endorsement declaration page',
        'Renewal and non-renewal communications',
        'Any notices of cancellation',
        'Any reinstatement documents'
      ];
    },
    mortgageeDocuments () {
      if (this.endorsement) {
        return ['Endorsement declaration page'];
      }
      return [
        'Declaration page',
        'Endorsement declaration page',
        'Any notices of cancellation',
        'Renewal and non-renewal communications'
      ];
    },
    mortgageeCompany () {
      var mortgagee1Company = this.$store.getters.getControlValue(this.module, 'Mortgagee1Company');
      return mortgagee1Company;
    },
    secondaryMortgageCompany () {
      var mortgagee2Company = this.$store.getters.getControlValue(this.module, 'Mortgagee2Company');
      return mortgagee2Company;
    }
  },
  async created () {
    this.loadSupplementalData();
    this.validationEnabled = true;
    this.validateInternal();
  },
  methods: {
    updateValue () {
      this.$store.commit('updateFormControl', {
          module: this.module,
          name: 'InsuredEmailOptions',
          value: this.insuredEmailOptions
      });
    },
    validateInternal () {
      // skip validation if we're not ready to validate...
      // or skip if nothing has changed (dirty flag is false)
      if (!this.validationEnabled || !this.isDirty) return;

      // reset
      this.emailError = null;
      this.notificationError = null;
      this.attestationError = null;

      // validate notification
      if (!((this.insuredEmailOptions.InsuredReceivesPolicyEmails === false || this.insuredEmailOptions.InsuredReceivesPolicyEmails === true))) {
        this.notificationError = ['Required'];
      }

      if (this.insuredEmailOptions.InsuredReceivesPolicyEmails === true) {
        // validate email
        if (!this.insuredEmailOptions.InsuredEmailAddress || !email.validate(this.insuredEmailOptions.InsuredEmailAddress)) {
          this.emailError = ['A valid email is required'];
        }

        // validate attestation checkbox
        if (!this.attestationValue) {
          this.attestationError = ['Required'];
        }
      }

      this.isValid = this.emailError === null && this.notificationError === null && this.attestationError === null;
      this.isDirty = false;
      if (this.insuredEmailOptions?.InsuredReceivesPolicyEmails === null) {
        return;
      };
      this.newValue = { ...this.insuredEmailOptions, isValid: this.isValid };
      this.emitValue();
    },
    emitValue () {
      this.$emit('change', this.newValue);
    },
    loadSupplementalData () {
      this.insuredEmailOptions = this.$store.getters.getControlValue(this.module, 'InsuredEmailOptions') ?? {
        InsuredReceivesPolicyEmails: null,
        InsuredEmailAddress: null,
        InsuredEmailAttestationText: null
      };
      this.attestationValue = false; // this needs to default off, until the user checks the box for _this_ session
    },
    updateAttestation (val) {
      this.attestationValue = val;
      this.updateInsuredEmailOptions({ name: 'InsuredEmailAttestationText', value: this.attestationValue ? this.attestationText : '' });
    },
    updateInsuredEmailOptions ({name, value}) {
      this.insuredEmailOptions = { ...this.insuredEmailOptions, [name]: value };
      this.updateValue();
      this.isDirty = true;
      this.validateInternal();
    }
  }
});
</script>

<style scoped lang="scss">
@import '@/style.scss';
.notifications-off {
  background-color: $gray6;
  border-radius: 4px;
  padding: 12px;

  .thumb-icon {
    font-size: 14px;
    color: $amwins-blue;
    margin-right: 8px;
  }
}

.notifications-on {
  display: flex;
  flex-direction: column;
  background-color: $gray6;
  border-radius: 4px;
  padding: 6px 12px 12px 6px;
}

.check-icon {
  margin-right: 8px;
  * {
    color: $tertiary-green;
  }
}

.mortgagee-wrapper {
  display: flex;
  flex-direction: column;

  .document-wrapper {
    display: flex;
    margin-top: 8px;
  }

  .mortgage-data-container {
    display: flex;
    margin-top: 12px;

    .mortgage-data-wrapper {
      margin: 8px 24px 0px 12px;
    }
  }
}

.small-title {
  font-size: 12px;
  padding-bottom: 4px;
}

.bold {
  font-weight: bold;
}

:deep(.notification-wrapper) {
  .description {
    margin-top: 0px;
    margin-bottom: 5px;
  }
}
</style>